html {
  --primary-font-family: Helvetica;
  --primary-color: Azure;
  --secondary-color: DarkSlateGrey;
  --tertiary-color: Aqua;
  --font-family: Helvetica;
  --font-size: 1.125rem;
}

body {
  position: fixed;
  width: 100%;
  height: 100%;
}

a {
  color: var(--tertiary-color);
}

.flex-container {
  display: flex;
  height: 100vh;
}

.flex-item {
  padding: 0px;
  margin: 0px;
  width: 49%;
  height: 100%;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
}

.flex-divider {
  width: 2%;
  height: 100%;
  background-color: var(--secondary-color);
}

.content {
  height: calc(100vh - 36px);
  width: calc(100% - 36px);
  margin: 0px;
  padding: 0px;
  border: 0px;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
  resize: none;
}

.textarea {
  padding: 18px;
}

.scroll {
  overflow: scroll;
}
